@import './src/variables';

@layer bootstrap, primeng, primeng1, theme;

@import "~bootstrap/dist/css/bootstrap.css" layer(bootstrap);
@import "~primeng/resources/primeng.css" layer(primeng1);
@import "primeng/resources/themes/md-light-indigo/theme.css" layer(theme);



.bg-image {
  background-attachment: fixed;
  background-image: linear-gradient(to bottom, $color-vni 0, rgba(0, 145, 167, .1) 100%), url('assets/img/bg_vcap_filter.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  height: 100vh;
  overflow: hidden;
}

@mixin transform-translate-x($value) {
  transform: translate3d($value, 0, 0);
}
